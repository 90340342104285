<template>
  <div>
    <b-row
      class="vertical"
      v-if="display === 'vertical'">
      <b-col cols="12" order="3">
        <b-button
          class="btn-google social lft"
          :class="{ part : !full }"
          type="button"
          @click="googleAuth()">
          <i class="fab fa-google fa-2x"></i>
          <span>&nbsp;&nbsp;Google</span>
        </b-button>
      </b-col>

      <b-col cols="12" order="1">
        <b-button
          class="btn-fb social mid"
          :class="{ part : !full }"
          type="button"
          @click="facebookHandler()"
          v-if="appId === null">
          <i class="fab fa-facebook-f fa-2x"></i>
          <span>&nbsp;&nbsp;Facebook</span>
        </b-button>
        <b-button
          class="btn-fb social mid"
          :class="{ part : !full }"
          type="button"
          ref="fbBtn"
          v-facebook-signin-button="appId"
          v-else>
          <i class="fab fa-facebook-f fa-2x"></i>
          <span>&nbsp;&nbsp;Facebook</span>
        </b-button>
      </b-col>

      <b-col cols="12" order="2">
        <b-button
          class="btn-twitter social lst"
          :class="{ part : !full }"
          type="button"
          @click="twitterAuth()">
          <i class="fab fa-twitter fa-2x"></i>
          <span>&nbsp;&nbsp;Twitter</span>
        </b-button>
      </b-col>

    </b-row>

    <div class="row horizontal" v-else>
      <div :class="[columnClass]">
        <b-button
          class="btn-google social lft"
          :class="{ part : !full }"
          type="button"
          @click="googleAuth()">
          <i class="fab fa-google fa-2x"></i>
          <span>&nbsp;&nbsp;Google</span>
        </b-button>
      </div>

      <div :class="[columnClass]">
        <b-button
          class="btn-fb social mid"
          :class="{ part : !full }"
          type="button"
          @click="facebookHandler()"
          v-if="appId === null">
          <i class="fab fa-facebook-f fa-2x"></i>
          <span>&nbsp;&nbsp;Facebook</span>
        </b-button>
        <b-button
          class="btn-fb social mid"
          :class="{ part : !full }"
          type="button"
          ref="fbBtn"
          v-facebook-signin-button="appId"
          v-else>
          <i class="fab fa-facebook-f fa-2x"></i>
          <span>&nbsp;&nbsp;Facebook</span>
        </b-button>
      </div>

      <div :class="[columnClass]">
        <b-button
          class="btn-twitter social lst"
          :class="{ part : !full }"
          type="button"
          @click="twitterAuth()">
          <i class="fab fa-twitter fa-2x"></i>
          <span>&nbsp;&nbsp;Twitter</span>
        </b-button>
      </div>
    </div>
    
    <modals-container />
  </div>
</template>

<script>
  import BirthdateModal from '@/components/common/BirthdayModal';
  import FacebookSignInButton from 'vue-facebook-signin-button-directive';
  import { mapActions } from 'vuex';

  export default {
    name  : 'SocialMediaButtons',
    props : {
      full : {
        type    : Boolean,
        default : true,
      },
      display : {
        type    : String,
        default : 'horizontal',
      },
    },
    data() {
      return {
        appId      : null,
        birthdate  : null,
        age        : 0,
        lsAgeCheck : null,
      }
    },
    directives : {
      FacebookSignInButton,
    },
    computed : {
      columnClass() {
        if (this.full)
          return 'col-md-4';
        return 'col-xs-4 col-md-12 col-xl-4';
      },
    },
    watch : {
      age(value) {
        if (value)
          this.appId = process.env.VUE_APP_FACEBOOK_APP_ID;
      },
    },
    methods : {
      ...mapActions({
        'socialLogin' : 'auth/socialLogin',
      }),

      /**
       * Facebook Custom Handler
       */
      facebookHandler() {
        if (!localStorage.age || !localStorage.birthdate)
          this.verifyAge('facebook');
        else {
          const that = this;
          setTimeout(() => {
            // Trigger FB Button click
            const elem = that.$refs.fbBtn;
            elem.click();
          }, 1000);
        }
      },

      /**
       * Verify Age
       * @param socialMedia
       */
      verifyAge(socialMedia) {
        this.$modal.show(BirthdateModal, {}, {
          height       : 'auto',
          width        : '100%',
          adaptive     : true,
          clickToClose : true,
          classes      : ['dob-modal'],
        }, {
          'before-close' : () => {
            if (localStorage.getItem('birthdate')) {
              this.birthdate = localStorage.getItem('birthdate');
              this.age = localStorage.getItem('age');

              if (socialMedia === 'twitter')
                this.twitterAuth();
              else if (socialMedia === 'google')
                this.googleAuth();
              else if (socialMedia === 'facebook') {
                const that = this;
                setTimeout(() => {
                  // Trigger FB Button click
                  const elem = that.$refs.fbBtn;
                  elem.click();
                }, 1000);
              }
            }
          },
        });
      },

      /**
       * twitter authentication request
       */
      twitterAuth() {
        // if (!localStorage.age || !localStorage.birthdate)
        //   this.verifyAge('twitter');
        // else {
          localStorage.socialRegPath = `/${this.$route.name}`;

          this.$http.post('auth/twitter/reverse')
            .then((response) => {
              localStorage.oauthToken = response.data.oauth_token;
              localStorage.oauthTokenSecret = response.data.oauth_token_secret;
              window.location =
                'https://api.twitter.com/oauth/authenticate?oauth_token=' +
                response.data.oauth_token;
            });
        // }
      },

      /**
       * On Facebook authentication successful request
       *
       * @param idToken
       */
      OnFacebookAuthSuccess(idToken) {
        if (!localStorage.age || !localStorage.birthdate)
          this.verifyAge('facebook');
        else {
          this.$http.post('auth/facebook/token', {
            'access_token' : idToken,
            'userType'     : (localStorage.getItem('age') < 18) ? 1 : 2,
            'inviterId'    : localStorage.inviterId || 0,
            'birthday'     : localStorage.birthdate,
          }).then((response) => {
            localStorage.socialRegPath = `/${this.$route.name}`;

            // store accessToken and refreshToken
            this.socialLogin({
              accessToken            : response.data.accessToken,
              refreshToken           : response.data.refreshToken,
              expiryAccessTokenUnix  : response.data.expiryAccessTokenUnix,
              expiryAccessTokenDate  : response.data.expiryAccessTokenDate,
              expiryRefreshTokenUnix : response.data.expiryRefreshTokenUnix,
              expiryRefreshTokenDate : response.data.expiryRefreshTokenDate,
            });

            this.$analytics.fbq.event('login-social', {
              'platform'    : 'website',
              'socialMedia' : 'facebook',
            });
          }).catch((e) => {
            this.$store.commit('notification/SET_ERROR', e.response.data.msg);
          });
        }
      },

      
      /**
       * On Facebook authentication failure request
       *
       * @param error
       */
      // eslint-disable-next-line no-unused-vars
      OnFacebookAuthFail(error) {
        // TODO: add on fail logic
      },

      /**
       * Goggle authentication request
       */
      // TODO: change gAuth process to get AuthCode to hide api key and secret
      // See https://www.npmjs.com/package/vue-google-oauth2 for reference
      googleAuth() {
        // if (!localStorage.age || !localStorage.birthdate)
        //   this.verifyAge('google');
        // else {
          this.$gAuth.signIn()
            .then(user => {
              // TODO: Update code when getting the access_token since the
              //   key (vc -> current key) that contains the access_token
              //   is always changing
              this.$http.post('auth/google/token', {
                'access_token' : user.vc.access_token,
                // 'userType'     : (localStorage.getItem('age') < 18) ? 1 : 2,
                'inviterId'    : localStorage.inviterId || 0,
                // 'birthday'     : localStorage.birthdate,
              }).then((response) => {
                localStorage.socialRegPath = `/${this.$route.name}`;

                // store accessToken and refreshToken
                this.socialLogin({
                  accessToken            : response.data.accessToken,
                  refreshToken           : response.data.refreshToken,
                  expiryAccessTokenUnix  : response.data.expiryAccessTokenUnix,
                  expiryAccessTokenDate  : response.data.expiryAccessTokenDate,
                  expiryRefreshTokenUnix : response.data.expiryRefreshTokenUnix,
                  expiryRefreshTokenDate : response.data.expiryRefreshTokenDate,
                });
                this.$analytics.fbq.event('login-social', {
                  'platform'    : 'website',
                  'socialMedia' : 'google',
                });
              }).catch((e) => {
                this.$store.commit(
                  'notification/SET_ERROR',
                  e.response.data.msg);
              });
            });
        // }
      },
    },
    mounted() {
      if ((localStorage.birthdate || localStorage.age) &&
        this.$route.path === '/login') {
        localStorage.removeItem('birthdate');
        localStorage.removeItem('age');
      }
      const that = this;
      this.lsAgeCheck = setInterval(function() {
        that.age = localStorage.getItem('age');
      }, 500)

    },
    beforeDestroy() {
      clearInterval(this.lsAgeCheck)
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #login-page {
    .social {
      border: 5px solid #e7932d;
    }
  }

  #signup-page {
    .social {
      margin: 0.75rem 0;
      border: 5px solid #e7932d;
    }
  }

  .social {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 10px;
    color: #FFF;
    
    &:hover {
      cursor: pointer;
    }

    span {
      font-family: "WickedMouse", cursive;
      font-size: 0.8em; //1.2em;
      -webkit-text-stroke: 1px black;
    }

    &.part {
      span {
        font-size: 0.7em;
      }

      .fa-2x {
        font-size: 0.9em;
      }
    }
  }

  .btn-google {
    background-color: $google-color;
    border-color: $google-color;
  }

  .btn-fb {
    background-color: $facebook-color;
    border-color: $facebook-color;
  }

  .btn-twitter {
    background-color: $twitter-color;
    border-color: $twitter-color;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .part {
      &.lft {
        padding-right: 2px;
      }
    
      &.mid {
        padding-left: 2px;
        padding-right: 2px;
      }
    
      &.lst {
        padding-left: 2px;
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {}
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {}
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    #signup-page, #login-page {
      .social {
        border: 3px solid #e7932d;
      }

      .fa-2x {
        padding: 0 0.2rem;
        font-size: 1.25em;
      }
    }
  }
  @media only screen and (min-width: $special) {
    .social {
      span {
        font-size: 0.75em;
      }
    }
    
    #signup-page, #login-page {
      .social {
        border: 3px solid #e7932d;
      }

      .fa-2x {
        padding: 0 0.2rem;
        font-size: 1.25em;
      }
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .social {
      span {
        font-size: 0.8em;
      }
      .fa-2x {
        font-size: 1.25em !important;
      }
    }
    #signup-page, #login-page {
      .social {
        border: 5px solid #e7932d;
      }

      .fa-2x {
        padding: 0 0.2rem;
        font-size: 1.25em;
      }
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .social {
      &.part {
        span {
          font-size: 1em;
        }

        .fa-2x {
          font-size: 1.5em;
        }
      }
    }

    #signup-page, #login-page {
      .social {
        border: 5px solid #e7932d;
        span {
          font-size: 0.9em;
        }
      }

      .fa-2x {
        padding: 0.5rem;
        font-size: 1.25em;
      }
    }
  }

  // @media only screen and (min-width: $xl6-min) {
  //   .social {
  //     &.part {
  //       span {
  //         font-size: 1.25em;
  //       }

  //       .fa-2x {
  //         font-size: 1.5em; //2em;
  //       }
  //     }
  //   }
  // }

  // @media only screen and (min-width: 768px) and (max-width: 1120px) {
  //   .social {
  //     span {
  //       font-size: 0.8em;
  //     }
  //   }
  // }
</style>